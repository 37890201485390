import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ManufacturersHero from "../../components/manufacturers-hero";
import ImageSide from "../../components/image-side";
import Badges from "../../components/badges";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import Container from "react-bootstrap/Container";
import MotForm from "../../components/mot-form";
import ImageSideContainer from "../../components/image-side-container";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const MotorhomePage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviewAuthor
				reviewDate
				reviewContent
			}
			schemaLogo: file(relativePath: { eq: "icon.png" }) {
				publicURL
			}
			pageData: wpPage(slug: { eq: "motorhome-bodyshop" }) {
				slug
				motorhomeBodyshopFields {
					motorhomeBodyshopBanner {
						motorhomeBodyshopBannerHeading
						motorhomeBodyshopBannerBackgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
						}
						motorhomeBodyshopBannerCta {
							title
							target
							url
						}
					}
					motorhomeBodyshopLeftContentRightImage {
						motorhomeBodyshopLeftContentHeading
						motorhomeBodyshopLeftContentText
						motorhomeBodyshopLeftContentButton {
							title
							target
							url
						}
						motorhomeBodyshopRightImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					motorhomeBodyshopReviews {
						motorhomeBodyshopReviewsHeading
					}
					motorhomeBodyshopLeftImageRightContent {
						motorhomeBodyshopRightContentHeading
						motorhomeBodyshopRightContentText
						motorhomeBodyshopLeftImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					motorhomeBodyshopNormalContent {
						motorhomeBodyshopNormalContentHeading
						motorhomeBodyshopNormalContentText
					}
					motorhomeBodyshopLogoSlider {
						motorhomeBodyshopLogoSliderHeading
						motorhomeBodyshopLogoSliderNotice
						motorhomeBodyshopLogos {
							nodes {
								... on WpVehicleLogo {
									vehicleLogoFields {
										logoUrl
										logoImage {
											node {
												altText
												localFile {
													publicURL
													childImageSharp {
														gatsbyImageData(
															formats: [AUTO, WEBP]
															quality: 100
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					motorhomeBodyshopServicesCards {
						motorhomeBodyshopServicesCardsHeading
						motorhomeBodyshopServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
					motorhomeBodyshopForm {
						motorhomeBodyshopFormHeading
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const {
		pageData: { seoFields, motorhomeBodyshopFields, slug },
	} = data;

	const {
		motorhomeBodyshopBanner,
		motorhomeBodyshopLeftContentRightImage,
		motorhomeBodyshopReviews,
		motorhomeBodyshopLeftImageRightContent,
		motorhomeBodyshopNormalContent,
		motorhomeBodyshopLogoSlider,
		motorhomeBodyshopServicesCards,
		motorhomeBodyshopForm,
	} = motorhomeBodyshopFields;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		description: `${seoFields?.metaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},

		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Motorhome Bodyshop",
				item: {
					url: `${siteUrl}/what-we-do/motorhome-bodyshop`,
					id: `${siteUrl}/what-we-do/motorhome-bodyshop`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{motorhomeBodyshopBanner && (
					<section>
						<ManufacturersHero
							title={motorhomeBodyshopBanner.motorhomeBodyshopBannerHeading}
							backgroundImage={
								motorhomeBodyshopBanner.motorhomeBodyshopBannerBackgroundImage
									.node?.localFile.childImageSharp.gatsbyImageData
							}
							cta={motorhomeBodyshopBanner.motorhomeBodyshopBannerCta}
						/>
					</section>
				)}

				{motorhomeBodyshopLeftContentRightImage &&
					!checkPropertiesForNull(motorhomeBodyshopLeftContentRightImage, [
						"motorhomeBodyshopRightImage",
						"motorhomeBodyshopLeftContentHeading",
					]) && (
						<div className="py-lg-8 py-5">
							<ImageSideContainer
								mPage
								image={
									motorhomeBodyshopLeftContentRightImage
										.motorhomeBodyshopRightImage.node?.localFile.childImageSharp
										.gatsbyImageData
								}
								imageAlt={
									motorhomeBodyshopLeftContentRightImage
										.motorhomeBodyshopRightImage.node?.altText
								}
								title={
									motorhomeBodyshopLeftContentRightImage.motorhomeBodyshopLeftContentHeading
								}
								order="last"
								imagePadding=" pb-5 pb-lg-0    "
								imageStyle="w-auto"
								text={
									motorhomeBodyshopLeftContentRightImage.motorhomeBodyshopLeftContentText
								}
								ctaButton={
									motorhomeBodyshopLeftContentRightImage.motorhomeBodyshopLeftContentButton
								}
							/>
						</div>
					)}

				<section>
					<Reviews
						title={motorhomeBodyshopReviews?.motorhomeBodyshopReviewsHeading}
					/>
				</section>
				{motorhomeBodyshopLeftImageRightContent &&
					!checkPropertiesForNull(motorhomeBodyshopLeftImageRightContent, [
						"motorhomeBodyshopRightContentHeading",
						"motorhomeBodyshopLeftImage",
					]) && (
						<div className="pt-5 pt-lg-8 body-left-image-content">
							<ImageSide
								image={
									motorhomeBodyshopLeftImageRightContent
										.motorhomeBodyshopLeftImage.node?.localFile.childImageSharp
										.gatsbyImageData
								}
								imageAlt={
									motorhomeBodyshopLeftImageRightContent
										.motorhomeBodyshopLeftImage.node?.altText
								}
								title={
									motorhomeBodyshopLeftImageRightContent.motorhomeBodyshopRightContentHeading
								}
								order="start"
								imagePadding="px-lg-5  pb-5 pb-lg-0 px-3 "
								text={
									motorhomeBodyshopLeftImageRightContent.motorhomeBodyshopRightContentText
								}
							/>
						</div>
					)}

				{motorhomeBodyshopNormalContent &&
					!checkPropertiesForNull(motorhomeBodyshopNormalContent, [
						"motorhomeBodyshopNormalContentHeading",
					]) && (
						<section className="pt-5 bg-white">
							<Container>
								<Row>
									<Col>
										<h2 className="my-4 fs-1">
											{
												motorhomeBodyshopNormalContent.motorhomeBodyshopNormalContentHeading
											}
										</h2>
										<SafeHtmlParser
											htmlContent={
												motorhomeBodyshopNormalContent?.motorhomeBodyshopNormalContentText
											}
										/>
									</Col>
								</Row>
							</Container>
						</section>
					)}

				{motorhomeBodyshopLogoSlider &&
					!checkPropertiesForNull(motorhomeBodyshopLogoSlider, [
						"motorhomeBodyshopLogoSliderHeading",
						"motorhomeBodyshopLogos",
					]) && (
						<Badges
							title={
								motorhomeBodyshopLogoSlider.motorhomeBodyshopLogoSliderHeading
							}
							text={
								motorhomeBodyshopLogoSlider.motorhomeBodyshopLogoSliderNotice
							}
							logos={motorhomeBodyshopLogoSlider.motorhomeBodyshopLogos?.nodes}
						/>
					)}
				<section id="book" className="pb-5  pt-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h2 className="text-center pb-4">
									{motorhomeBodyshopForm?.motorhomeBodyshopFormHeading ||
										"BOOK YOUR APPOINTMENT"}
								</h2>
								<div
									style={{ background: "#F9F9F9", borderRadius: "18px" }}
									className="p-md-5 p-4"
								>
									<MotForm page="Motorhome Bodyshop" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* {motorhomeBodyshopServicesCards &&
          !checkPropertiesForNull(motorhomeBodyshopServicesCards, [
            "motorhomeBodyshopServicesCardsHeading",
            "motorhomeBodyshopServicesCards",
          ]) && (
            <OurServicesSection
              title={
                motorhomeBodyshopServicesCards.motorhomeBodyshopServicesCardsHeading
              }
              services={
                motorhomeBodyshopServicesCards.motorhomeBodyshopServicesCards
                  ?.nodes
              }
            />
          )} */}
			</Layout>
		</>
	);
};

export default MotorhomePage;
